<template>
  <ul>
    <menu-options-item close label="Abrir" @click="$emit('abrir')" />
    <menu-options-item close label="Excluir" @click="$emit('excluir')"  label-class="text-negative" />
    <div class="diviser"></div>
    <menu-options-item label="Recibo" @click="recibo" close icon="file-pdf" />
    <menu-options-item label="Baixar Fatura" icon="file-pdf" close @click="fatura"  />
    <menu-options-item v-if="m.tipo === 1 && !m.pago" label="Cobrar" icon="envelope" close @click="cobrar" />
    <menu-options-item v-if="m.tipo === 1 && m.pago" label="Enviar Recibo" icon="envelope" close @click="enviarRecibo" />
  </ul>
</template>

<script>
import {recibo, fatura, cobrar} from '@/domain/financeiro/services'
import MenuOptionsItem from '../../../layout/context-menu/context-window-options-item'
import {donwloadFile} from "@/utils/downloadFile"
export default {
  name: 'list-menu',
  props: ['m'],
  components: {MenuOptionsItem},
  methods: {
    recibo () {
      recibo(this.m.id)
          .then((response) => {
            donwloadFile(response)
          })
          .catch(error => {
            this.alertApiError(error)
          })

    },
    fatura () {
      fatura(this.m.id)
          .then((response) => {
            donwloadFile(response)
          })
          .catch(error => {
            this.alertApiError(error)
          })

    },
    cobrar () {
      this.$uloc.dialog({
        title: `Cobrar ${this.m.pessoa.name}`,
        message: `Você tem certeza que deseja enviar o aviso de cobrança desta fatura?`,
        ok: 'Sim, enviar',
        cancel: 'Não'
      }).then(() => {
        this.$uloc.loading.show()
        cobrar(this.m.id)
            .then((response) => {
              this.$uloc.notify({
                color: 'positive',
                message: `Cobrança enviada com sucesso!`
              })
              this.$uloc.loading.hide()
            })
            .catch(error => {
              this.$uloc.loading.hide()
              this.alertApiError(error)
            })
      }).catch(() => {})
    },
    enviarRecibo () {
      this.$uloc.dialog({
        title: `Cobrar ${this.m.pessoa.name}`,
        message: `Você tem certeza que deseja enviar o recibo de pagamento desta fatura?`,
        ok: 'Sim, enviar',
        cancel: 'Não'
      }).then(() => {
        this.$uloc.loading.show()
        recibo(this.m.id, true)
            .then((response) => {
              this.$uloc.notify({
                color: 'positive',
                message: `Recibo enviado com sucesso!`
              })
              this.$uloc.loading.hide()
            })
            .catch(error => {
              this.$uloc.loading.hide()
              this.alertApiError(error)
            })
      }).catch(() => {})
    }
  }
}
</script>
