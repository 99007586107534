<template>
  <div class="contas-resumo font-12 font-avenir text-grey-7" :class="resumoCss">
    <div v-if="!isEmbedComponent" class="at-title non-selectable">RESULTADO PREVISTO PARA O MÊS <a @click="$emit('hide')"><i class="fa" :class="hide ? 'fa-eye-slash text-blue-grey' : 'fa-eye'" /></a> </div>
    <div v-if="!isEmbedComponent" class="resume-value" :class="{'text-negative': calculos.resultadoPrevistoMes < 0}">
      <span v-if="!hide">R$ {{ calculos.resultadoPrevistoMes && calculos.resultadoPrevistoMes|moeda }}</span>
      <span v-else class="hidden-bar non-selectable">R$ 9999,99</span>
    </div>
    <e-row class="m-t">
      <e-col class="m-r-sm">
        <e-row class="font-11">
          <e-col>RECEBIMENTOS</e-col>
          <e-col class="text-right" style="max-width: 30px">
            <span v-if="!hide">{{convertePorcentagem(calculos.porcentagemRecebidos) }}%</span>
            <span v-else class="hidden-bar non-selectable">R$ 9999,99</span>
          </e-col>
        </e-row>
        <u-progress v-if="!hide" class="progress-positive" :percentage="calculos.porcentagemRecebidos" color="positive"
                    height="6px"/>
        <e-row class="font-11 m-t-sm">
          <e-col><strong>Recebido</strong></e-col>
          <e-col class="text-right font-bold positive">
            <span v-if="!hide">R$ {{ calculos.receitasPagas|moeda }}</span>
            <span v-else class="hidden-bar non-selectable">R$ 9999,99</span>
          </e-col>
        </e-row>
        <e-row class="font-11">
          <e-col>Previsto</e-col>
          <e-col class="text-right font-bold">
            <span v-if="!hide">R$ {{ calculos.totalReceitas|moeda }}</span>
            <span v-else class="hidden-bar non-selectable">R$ 9999,99</span>
          </e-col>
        </e-row>
      </e-col>
      <e-col class="m-l-sm">
        <e-row class="font-11">
          <e-col>DESPESAS</e-col>
          <e-col class="text-right" style="max-width: 30px">
            <span v-if="!hide">{{ convertePorcentagem(calculos.porcentagemPagos) }}%</span>
            <span v-else class="hidden-bar non-selectable">R$ 9999,99</span>
          </e-col>
        </e-row>
        <u-progress v-if="!hide" class="progress-negative" :percentage="calculos.porcentagemPagos" color="negative" height="6px"/>
        <e-row class="font-11 m-t-sm">
          <e-col><strong>Pago</strong></e-col>
          <e-col class="text-right font-bold negative">
            <span v-if="!hide">R$ {{ calculos.despesasPagas|moeda }}</span>
            <span v-else class="hidden-bar non-selectable">R$ 9999,99</span>
          </e-col>
        </e-row>
        <e-row class="font-11">
          <e-col>Previsto</e-col>
          <e-col class="text-right font-bold">
            <span v-if="!hide">R$ {{ calculos.totalDespesas|moeda }}</span>
            <span v-else class="hidden-bar non-selectable">R$ 9999,99</span>
          </e-col>
        </e-row>
      </e-col>
    </e-row>
  </div>
</template>

<script>
import {UProgress} from 'uloc-vue'
import ERow from "@/components/layout/components/Row"
import ECol from "@/components/layout/components/Col"

export default {
  name: "Resumo",
  props: ['stats', 'isEmbedComponent', 'hideMoneyInfo'],
  data () {
    return {
    }
  },
  computed: {
    s() {
      if (this.stats) {
        return this.stats
      }
      return {}
    },
    calculos() {
      let totalReceitas = 0
      let totalDespesas = 0
      let receitasPagas = 0
      let despesasPagas = 0
      let saldo = 0
      let resultadoPrevistoMes = 0
      let porcentagemRecebidos = 0
      let porcentagemPagos = 0
      const s = this.s
      if (s.saldo && s.saldo.valor) {
        saldo = saldo + Number(s.saldo.valor)
      }
      if (s.contasPagarMes && s.contasPagarMes.valor) {
        totalDespesas = totalDespesas + Number(s.contasPagarMes.valor)
      }
      if (s.contasReceberMes && s.contasReceberMes.valor) {
        totalReceitas = totalReceitas + Number(s.contasReceberMes.valor)
      }
      // Pagas
      if (s.situacaoMes && s.situacaoMes.recebimentos) {
        receitasPagas = receitasPagas + Number(s.situacaoMes.recebimentos.valorPago)
      }
      if (s.situacaoMes && s.situacaoMes.pagamentos) {
        despesasPagas = despesasPagas + Number(s.situacaoMes.pagamentos.valorPago)
      }
      resultadoPrevistoMes = totalReceitas - totalDespesas
      saldo = (saldo + totalReceitas) - Math.abs(totalDespesas)
      porcentagemRecebidos = (receitasPagas * 100) / totalReceitas
      porcentagemPagos = (despesasPagas * 100) / totalDespesas
      return {
        saldo,
        resultadoPrevistoMes,
        totalReceitas,
        totalDespesas,
        receitasPagas,
        despesasPagas,
        porcentagemRecebidos,
        porcentagemPagos
      }
    },
    hide () {
      return this.hideMoneyInfo
    },
    resumoCss () {
      const css = []
      this.hide && css.push('hide-info')
      return css
    }
  },
  components: {
    ECol,
    ERow,
    UProgress
  },
  methods: {
    convertePorcentagem(v) {
      v = String(v)
      v = v.split('.')
      return !isNaN(v[0]) ? v[0] : 100
    }
  }
}
</script>
